<template>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="12"
      >
        <v-card>
          <v-card-title class="align-start">
            <span >CSAT Results</span>
            <v-spacer></v-spacer>
            <v-menu
              v-model="fromMenu"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
              :close-on-content-click="false"
              >
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="fromDate"
                  label="From"
                  dense
                  hide-details="auto"
                  :prepend-icon="icons.mdiCalendarBlankOutline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-0 shrink mr-4"
                  
              ></v-text-field>
              </template>
              <v-date-picker
                  v-model="fromDate"
                  @click:date="getCSATResults"
                  @input="fromMenu = false"
              >
              </v-date-picker>
            </v-menu>
            <v-menu
              v-model="toMenu"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
              :close-on-content-click="false"
              >
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="toDate"
                  label="To"
                  dense
                  hide-details="auto"
                  :prepend-icon="icons.mdiCalendarBlankOutline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-0 shrink mr-4"
                  
              ></v-text-field>
              </template>
              <v-date-picker
                  v-model="toDate"
                  @click:date="getCSATResults"
                  @input="toMenu = false"
              >
              </v-date-picker>
            </v-menu>
            <v-btn-toggle
                mandatory
                dense
                color="warning"
                class="mr-4"
              >
              
                <v-btn
                  outlined
                  color="warning"
                  v-for="split, index in splitOptions"
                  :key="index"
                  @click="onActiveSplitViewChange(split)"
                >
                  {{split.label}}
                </v-btn>
                
              </v-btn-toggle>
            <v-btn-toggle
              mandatory
              dense
              color="primary"
            >
            
              <v-btn
                outlined
                color="primary"
                v-for="time, index in timelineViewOptions"
                :key="index"
                @click="onActiveTimelineViewChange(time)"
              >
                {{time.label}}
              </v-btn>
              
            </v-btn-toggle>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <apex-chart
          :refresh="refreshCSAT"
          :title="`Overall Ratings`"
          :type="`bar`"
          :data="CSATSeries"
          :yaxisLabel="RatingLabels"
          :xaxisLabel="xLabel"
          :stroke="stroke"
        >

        </apex-chart>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <apex-chart
          :refresh="refreshCSAT"
          :title="`Results`"
          :type="`bar`"
          :data="res"
          :xaxisLabel="xResultsLabel"
          :yaxisLabel="QualityRatingLabels"
          :stroke="stroke"
        >

        </apex-chart>
      </v-col>
      
    </v-row>
  </template>

  <script> 
  import { onUnmounted, ref, onMounted, getCurrentInstance, watch } from '@vue/composition-api'
  import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
  import ApexChart from '@/views/components/charts/apex/ApexChart.vue'
  import useCSATDashboard from './useCSATDashboard'
  import store from '@/store'
  import csatStoreModule from './csatStoreModule'
  import { formatDateToMonthYearShort, removeDuplicate } from '@core/utils/filter'
import { getVuetify } from '@core/utils'
  
  // eslint-disable-next-line object-curly-newline
  import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, 
          mdiDotsVertical, mdiLabelOutline, mdiCalendarBlankOutline,
          mdiDownload, mdiEmoticonOutline } from '@mdi/js'
  
  export default {
    components: {
        AppCardActions,
        ApexChart,
    },
    setup() {
        const CSAT_DASHBOARD_APP_STORE_MODULE_NAME = 'app-csat-dashboard'
    
        // Register module
        if (!store.hasModule(CSAT_DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(CSAT_DASHBOARD_APP_STORE_MODULE_NAME, csatStoreModule)
  
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CSAT_DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(CSAT_DASHBOARD_APP_STORE_MODULE_NAME)
        })
  
        const { 
            fetchCSATResults, 
            downloadCSAT,
            refreshCSAT,
            CSATData,
            CSATSeries,
            CSATKeys,
            RatingLabels,
            QualityRatingLabels
        } = useCSATDashboard()
  

        const labelsMonth = ref([])
        const fromMenu = ref(false)
        const toMenu = ref(false)
        const fromDate = ref(`${new Date().getFullYear()}-01-01`)
        const toDate = ref(`${new Date().getFullYear()}-12-31`)
        const res = ref([])
        const xLabel = ref([])
        const xResultsLabel = ref([])
        const stroke = ref({
            curve: 'smooth',
            width:1,
            lineCap: 'round'
        })
  
        const clickDownloadCSAT = () => {
          downloadCSAT({fromDate, toDate})
        }
      
        const groupDataApex = (data, transformer, xAxis, attr) => {
          // xAxis list all the elements for the x axis in the chart (labels).
          // Go through each element of xAxis, if elemt date isn't in data (filter)
          // them reducer initial value is 0 for budget
          xLabel.value = []
          return xAxis.map((date) => {
            const year = `${new Date(date).getFullYear()}`
            const quarter = `Q${Math.floor(new Date(date).getMonth() / 3 + 1)} ${year}`
            const month = `${new Date(date).toLocaleString('default', { month: 'short' })}' ${year}`
            const columnLabel = activeTimelineView.value.value === 'month' ? month : activeTimelineView.value.value === 'quarter' ? quarter : year
            xLabel.value.push(columnLabel)
              return {
              x: columnLabel,
              y: data.filter(el => transformer(el.date) === date)
                  .map(el => {
                  const value = el[attr] ? el[attr] : 1
                  return value
                  })
                  .reduce((total, item) =>  item, 0).toFixed(1)
              }
          })
        }
        const formatSeries = (key, attr) => {
              
              const datakey = [...new Set(CSATData.value.map(item => item[key]))];
              let datasets = []

              datakey.map((item, index) => {
                  datasets.push({
                      name: item,
                      data: groupDataApex(CSATData.value.filter(el => el[key] === item), formatDateToMonthYearShort, labelsMonth.value, attr),
                  })
              })
              

              return datasets
        }
        
        const formatResultSeries = () => {
          let datasets = {}
          xResultsLabel.value = []
          for (const xlabel of CSATData.value){
            const year = `${new Date(xlabel.date).getFullYear()}`
            const quarter = `Q${Math.floor(new Date(xlabel.date).getMonth() / 3 + 1)} ${year}`
            const month = `${new Date(xlabel.date).toLocaleString('default', { month: 'short' })}' ${year}`
            const columnLabel = activeTimelineView.value.value === 'month' ? month : activeTimelineView.value.value === 'quarter' ? quarter : year
            if (xlabel.office) {
              xResultsLabel.value.push(`${xlabel.office} - ${columnLabel}`)
            }else{
              xResultsLabel.value.push(`${columnLabel}`)

            }
          }
          for(const key in CSATKeys.value){

            CSATData.value.map(data => {
              if (!(key in datasets)) datasets[key] = {name: CSATKeys.value[key].label, data: [data[key]]}
              else datasets[key]['data'].push(data[key])
            })
            
          }
          return Object.keys(datasets).map(k => datasets[k])
        }

        const splitOptions = ref([
          { label: 'Overall', value: false},
          { label: 'Office', value: true},
        ])
        const activeSplitView = ref(splitOptions.value[0])

        const onActiveSplitViewChange = (item) => {
          activeSplitView.value = item;
          getCSATResults()
        }

          const timelineViewOptions = ref([
            { label: 'Month', value: 'month',},
            { label: 'Quarter', value: 'quarter'},
            { label: 'Year', value: 'year'}
          ])
        const activeTimelineView = ref(timelineViewOptions.value[0])

        const onActiveTimelineViewChange = (item) => {
          activeTimelineView.value = item;
          getCSATResults()
        }


        const getCSATResults = () => {
          fetchCSATResults({
                from: fromDate.value,
                to: toDate.value,
                group_by_date: activeTimelineView.value.value,
                group_by_office:activeSplitView.value.value
              })
        }


      onMounted(() => {
          getCSATResults()
      })


      watch(
            CSATData,
            () => {
                labelsMonth.value = CSATData.value.map(entry => formatDateToMonthYearShort(entry.date))
                          .reduce(removeDuplicate, [])
                CSATSeries.value = formatSeries('office', 'rating', false)
                res.value = formatResultSeries()
            }
        )
  
  
      return {
        res,
        xLabel,
        xResultsLabel,
        fromDate,
        toDate,
        fromMenu,
        toMenu,
        CSATData,
        CSATSeries,
        stroke,
        getCSATResults,
        RatingLabels,
        QualityRatingLabels,
        refreshCSAT,
        clickDownloadCSAT,
        timelineViewOptions,
        splitOptions,
        onActiveSplitViewChange,
        onActiveTimelineViewChange,
        // icons
        icons: {
          mdiDotsVertical,
          mdiTrendingUp,
          mdiAccountOutline,
          mdiLabelOutline,
          mdiCurrencyUsd,
          mdiCalendarBlankOutline,
          mdiDownload,
          mdiEmoticonOutline,
        },
      }
    }
  }
  </script>
  
import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCSATResults(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/results_summary/`,{
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                  })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)})
            })
        },
        downloadCSAT(ctx, queryParams){
            store.commit('app/LOADING', true)

            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/export_results/xlsx`,{
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params),
                    responseType: 'blob',
                    headers: {
                        'Authorization': localStorage.getItem('accessToken'),
                        'Content-Type': 'application/vnd.ms-excel;charset=UTF-8'
                        
                    }
                  })
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            
            })
        },
        
    }
}

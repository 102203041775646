import store from '@/store'
import router from '@/router'
import { foundryRequest } from '@/config/authConfig';
import { ref, getCurrentInstance } from '@vue/composition-api'
import { getVuetify } from '@core/utils'

export default function useDashboardSales() {
  
    const vm = getCurrentInstance().proxy
    
    const refreshCSAT = ref(false)
    const CSATData = ref([])
    const CSATSeries = ref([])
    const RatingLabels = (value) => {
      const labels = {
        1: 'Not well at all',
        2: 'Not very well',
        3: 'Quite well',
        4: 'Very well'
      }
      return labels[value]
    }
    const QualityRatingLabels = (value) => {
      const labels = {
        1: 'It went great, no issues',
        2: 'A couple of bumps but overall, it was fine',
        3: 'Quite rocky, but was well managed',
        4: 'Quite rocky, could have been better managed',
        0: 'N/A',
        5: 'Big problems here	'
      }
      return labels[value]
    }

    const CSATKeys = ref({
      'shopping':{
        label: 'Shopping'
      },
      'shelf':{
        label: 'Shelf Creation'
      },
      'stim': {
        label: 'Stim Management'
      },
      'link':{
        label: 'Test Link'
      },
      'pre-recruitment':{
        label: 'Pre-recruitment'
      },
      'field':{
        label: 'Field Work'
      },
      'cost':{
        label: 'Cost Management'
      },
      'time':{
        label: 'Time Management'
      },
      'communication': {
        label: 'Communication'
      },
      'automated_report': {
        label: 'Auto. Reporting'
      },
    })


    const fetchCSATResults = async (params) => {
        refreshCSAT.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
          .dispatch('app-csat-dashboard/fetchCSATResults', {
            ...params
          })
          .then(response => {
            CSATData.value = response.data
            
            refreshCSAT.value = false
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
                CSATData.value = []
            }
            if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
          })
    }

    const flattenData = (data) => {
      return data.map((item) => {
        return {
          ...item,
          year: parseInt(item.date.substring(0,4)),
          // month: `${dayjs(item.date).get('month')+1}-${this.monthMapping[dayjs(item.date).get('month')]}`,
          month: parseInt(item.date.substring(5,7)),
          week: getWeek(new Date(item.date)),
          day: parseInt(item.date.substring(8,10))
        }
      });
    }
    const getWeek = (date) => {
      var janOne = new Date(date.getFullYear(), 0, 2);
      return Math.ceil((((date - janOne) / 86400000) + date.getDay() + 1) / 7);
    }


    const downloadCSAT = async ({from, to}) => {
      
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-csat-dashboard/downloadCSAT', {
          from,
          to
        })
        .then(response => {
          const data = response.data
          // console.log(flatten(data))
          // const filtered = data.map(obj => {

          //     // get totals to add them later to keep column order (or use `header` param for columns order)
          //     return flatten(obj)
          // });
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
    
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `CSAT - ${new Date().toJSON()}.xlsx`);
          document.body.appendChild(fileLink);
    
          fileLink.click();
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })

    }

    return {
        refreshCSAT,
        CSATData,
        CSATSeries,
        CSATKeys,
        RatingLabels,
        QualityRatingLabels,
        fetchCSATResults,

        downloadCSAT
    }

}